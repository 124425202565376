"use client";

import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";
import { routes } from "@/lib/utils";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { useRouter } from "next-nprogress-bar";
import { useTranslation } from "@/i18n/client";
import { TopBlueHeader } from "../TopBlueHeader";
import CloseIcon from "@/assets/icon/cross_icon.svg?url";
import ArrowIcon from "@/assets/static/arrowIcon.svg?url";
import { useAppDispatch, useAppSelector } from "@/lib/store";
import Button from "@/components/common/shared/Button/button";
import { getContentfulImgData } from "@/lib/services/contentful";
import ImageWithFallback from "@/components/common/ImageWithFallback";
import {
  setActiveMenuKey,
  setActiveSidebar,
} from "@/lib/store/reducers/common";

// Dynamically import MegaMenu and MobileMegaMenu for lazy loading
const MegaMenu = dynamic(() => import("@/components/common/shared/MegaMenu"));
const MobileMegaMenu = dynamic(() =>
  import("@/components/common/shared/MobileMegaMenu"),
);

export const MainMenu = ({ megaMenu, locale }) => {
  const router = useRouter();
  const pathname = usePathname();
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["header"]);

  const [isBack, setIsBack] = useState(false);
  const { activeSidebar, activeMenuKey } = useAppSelector(
    (state) => state.common,
  );

  const handleMouseEnter = (index, hasChildItems) => {
    if (hasChildItems) {
      dispatch(setActiveMenuKey(index));
    }
  };

  const handleMouseLeave = () => {
    dispatch(setActiveMenuKey(null));
  };

  useEffect(() => {
    if (activeMenuKey && activeSidebar) {
      document.body.classList.add("megaMenu-active");
    } else {
      document.body.classList.remove("megaMenu-active");
    }

    return () => {
      document.body.classList.remove("megaMenu-active");
    };
  }, [activeMenuKey]);

  const closeSidebar = () => {
    dispatch(setActiveSidebar(false));
  };

  const handleClick = (menuKey, hasChildItems) => {
    dispatch(setActiveMenuKey(activeMenuKey === menuKey ? menuKey : null));
    setIsBack(false);
    // Close the sidebar if the menu item does not have child items
    if (!hasChildItems) {
      closeSidebar();
    }
  };

  const handleBackClick = () => {
    setIsBack(true);
  };

  const handleLinkClick = (e, link) => {
    e.stopPropagation();
    closeSidebar();

    // Remove Unnecessary Loader
    let currentPath = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;
    if (currentPath === link) {
      setTimeout(() => {
        const htmlEl = document.querySelector("html");
        if (htmlEl) {
          htmlEl.classList.remove("nprogress-busy");
        }
      }, 0);
    }
    router.push(link);
  };

  return (
    <div className={`mainMenu  ${activeSidebar ? "active" : ""}`}>
      <div className="xxs:hidden block">
        <TopBlueHeader locale={locale} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            {/* Desktop Menu */}
            <div
              className="lg:hidden block w-auto my-[20px] me-auto cursor-pointer"
              onClick={closeSidebar}
            >
              <Image
                width={32}
                height={32}
                src={CloseIcon}
                alt="ArrowIcon"
                className="lg:hidden block"
              />
            </div>
            <ul className="nav-link-container">
              {megaMenu?.map((menuItem) => {
                const {
                  name,
                  menuKey,
                  link,
                  hasChildItems,
                  childItems,
                  menuIcon,
                } = menuItem.fields || {};
                const imageData = getContentfulImgData(menuIcon);
                return (
                  <li
                    key={menuKey}
                    className={`navlink ${
                      activeMenuKey === menuKey ? "active" : ""
                    }`}
                    onMouseLeave={handleMouseLeave}
                    onMouseEnter={() =>
                      handleMouseEnter(menuKey, hasChildItems)
                    }
                  >
                    {hasChildItems && (
                      <Button
                        className="lg:hidden flex"
                        onClick={() => handleClick(menuKey, hasChildItems)}
                        onTouchStart={() => handleClick(menuKey, hasChildItems)}
                      >
                        <div className="flex items-center">
                          <ImageWithFallback
                            width={24}
                            height={24}
                            alt={
                              imageData?.title ||
                              imageData?.description ||
                              imageData?.fileName
                            }
                            src={imageData.imageUrl}
                            className="lg:hidden block mr-4"
                          />
                          <span>{name}</span>
                        </div>

                        <Image
                          width={9.41}
                          height={5.4}
                          src={ArrowIcon}
                          alt="ArrowIcon"
                          className="lg:ms-[9px] ms-1  rotate-[270deg] lg:rotate-0 lg:max-w-max xl:max-w-[7px]"
                        />
                      </Button>
                    )}
                    <Link
                      href={link}
                      prefetch={false}
                      onClick={(e) => handleLinkClick(e, link)}
                      onTouchStart={(e) => handleLinkClick(e, link)}
                      className={`${hasChildItems ? "lg:flex hidden" : "flex"}`}
                    >
                      <div className="flex items-center">
                        <ImageWithFallback
                          width={24}
                          height={24}
                          alt={
                            imageData?.title ||
                            imageData?.description ||
                            imageData?.fileName
                          }
                          src={imageData.imageUrl}
                          className="lg:hidden block mr-4"
                        />
                        <span>{name}</span>
                      </div>
                      {hasChildItems && (
                        <Image
                          width={9.41}
                          height={5.4}
                          src={ArrowIcon}
                          alt="ArrowIcon"
                          className="lg:ms-[9px] ms-1  rotate-[270deg] lg:rotate-0 lg:max-w-[7px]"
                        />
                      )}
                    </Link>

                    {hasChildItems && (
                      <>
                        <MegaMenu childItems={childItems} />
                        {activeSidebar && (
                          <div
                            className={`mobile-menu ${
                              activeMenuKey === menuKey && !isBack
                                ? "active"
                                : ""
                            }`}
                          >
                            <MobileMegaMenu
                              menuName={name}
                              menuLink={link}
                              menuIcon={imageData}
                              childItems={childItems}
                              onBackClick={handleBackClick}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
              <li className="static-links mt-12">
                <Link
                  prefetch={false}
                  href={routes.storeLocator}
                  onClick={() => closeSidebar()}
                >
                  {t("links.store_locator")}
                </Link>
              </li>
              <li className="static-links mt-1">
                <Link
                  prefetch={false}
                  href={routes.aboutUs}
                  onClick={() => closeSidebar()}
                >
                  {t("links.about_us")}
                </Link>
              </li>
              <li className="static-links mt-1">
                <Link
                  prefetch={false}
                  href={routes.contactUs}
                  onClick={() => closeSidebar()}
                >
                  {t("links.contact_us")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
