"use client";

import { memo } from "react";
import dynamic from "next/dynamic";
import classNames from "classnames";
import Input from "@/components/common/shared/Input/input";
const SearchSuggestioBox = dynamic(
  () => import("@/components/common/shared/SearchSuggestionBox"),
  {
    ssr: false,
  },
);

// Dynamically import and memoize SearchBoxPlaceholder
const Placeholder = dynamic(
  () =>
    import("./SearchBoxPlaceholder").then((mod) =>
      memo(mod.SearchBoxPlaceholder),
    ),
  {
    ssr: false, // Optional: only for client-side rendering
  },
);

export default function SearchBox({
  t,
  searching,
  isFocused,
  searchTerm,
  isSearched,
  suggestions,
  setIsFocused,
  isMobileScreen,
  enterKeyHandler,
  handleResultLink,
  handleClearSearch,
  queryToSearchItems,
  handleSearchChange,
}) {
  return (
    <>
      <div
        className={classNames("searchBox relative", {
          focused: isFocused,
          suggestions: isFocused && (searching || isSearched),
        })}
      >
        <Input
          type="search"
          id="desktop-search"
          placeholder="Search for parts"
          defaultValue={searchTerm}
          onKeyDown={enterKeyHandler} // Handles Enter key press
          onClear={handleClearSearch}
          onChange={(e) => {
            setIsFocused(true);
            handleSearchChange(e); // Syncs and debounces search input
          }}
          onSearch={queryToSearchItems}
          onFocus={() => setIsFocused(true)}
          icon={searchTerm ? "cross" : "search"}
          onBlur={() =>
            setTimeout(() => {
              setIsFocused(false);
            }, 500)
          }
        />

        {!searchTerm && <Placeholder isFocused={isFocused} t={t} />}

        {!isMobileScreen && (
          <div className="search-suggestion-box">
            <SearchSuggestioBox
              isFetching={searching}
              searchTerm={searchTerm}
              suggestions={suggestions}
              handleResultLink={handleResultLink}
            />
          </div>
        )}
      </div>

      <div
        tabIndex={0}
        role="button"
        className="search-bg"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      ></div>
    </>
  );
}
