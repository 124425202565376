"use client";

import Link from "next/link";
import dynamic from "next/dynamic";
import SearchBox from "./searchBox";
import { useAppSelector } from "@/lib/store";
import Logo from "@/assets/static/Logo.svg?url";
import { handleLogout } from "@/lib/services/auth";
import ArrowDesktop from "@/assets/static/headerArrow.svg";
import { useSearchItems } from "@/lib/hooks/useSearchItems";
import Button from "@/components/common/shared/Button/button";
import { getCustomerToken } from "@/lib/store/reducers/customer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { routes, getPrice, handleToastSuccess } from "@/lib/utils";
import ImageWithFallback from "@/components/common/ImageWithFallback";
import { getCartCounts, getCartPrice } from "@/lib/store/reducers/cart";
import { setActiveSidebar, setMiniTrolley } from "@/lib/store/reducers/common";
import Touchable from "@/components/common/shared/Touchable";

const MblSearch = dynamic(() => import("./MblSearchBox"), {
  ssr: false,
});

const CustomTooltip = dynamic(
  () => import("@/components/common/shared/ToolTip"),
  {
    ssr: false,
  },
);

const AccountTooltip = dynamic(
  () => import("@/components/common/shared/AccountTooltip"),
  {
    ssr: false,
  },
);

const Dropdown = dynamic(
  () => import("@/components/common/shared/Dropdown/dropdown"),
  {
    ssr: false,
  },
);

export const CenterHeaderPart = ({ tokenCipher }) => {
  const cartPrice = useAppSelector(getCartPrice);
  const { totalCount } = useAppSelector(getCartCounts);
  const customerToken = useAppSelector(getCustomerToken);
  const { activeSidebar } = useAppSelector((state) => state.common);
  const {
    data: {
      searching,
      isFocused,
      searchTerm,
      isSearched,
      suggestions,
      isSearchOpen,
      isMobileScreen,
    },
    methods: {
      t,
      router,
      dispatch,
      setIsFocused,
      setIsSearchOpen,
      enterKeyHandler,
      handleResultLink,
      handleClearSearch,
      queryToSearchItems,
      handleSearchChange,
    },
  } = useSearchItems({
    afterSearch: () => {
      document.body.style.overflow = "unset";
    },
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(tokenCipher ? true : false);

  const handleLogoutClick = useCallback(() => {
    handleLogout({
      dispatch,
      onSuccess: () => {
        handleToastSuccess(t("common:messages.success.logout"));
        router.push(routes.logout);
      },
    });
  }, [router, dispatch, t]);

  const options = useMemo(() => {
    return [
      { label: t("dropdown.account"), href: routes.accountDashboard },
      { label: t("dropdown.garage"), href: routes.garage },
      { label: t("dropdown.orders"), href: routes.orders },
      { label: t("dropdown.addressBook"), href: routes.addressBook },
      { label: t("dropdown.accountInfo"), href: routes.accountInfo },
      { label: t("dropdown.newsletter"), href: routes.newsletter },
      {
        label: t("dropdown.logout"),
        onClick: handleLogoutClick,
      },
    ];
  }, [handleLogoutClick, t]);

  const openMiniTrolley = () => {
    dispatch(setMiniTrolley(true));
  };

  const handleSearchClick = (e) => {
    const isOpen = typeof e === "boolean" ? e : !isSearchOpen;

    setIsSearchOpen(isOpen);
    if (isOpen) {
      document.documentElement.scrollTop = 0;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    setTimeout(() => {
      document.body.style.overflow = isOpen ? "hidden" : "unset";
      document.body.classList.add("search-fixed");
    }, 500);
  };

  const openSidebar = () => {
    setIsSearchOpen(false);
    document.body.style.overflow = "unset";
    dispatch(setActiveSidebar(true));
  };

  useEffect(() => {
    if (activeSidebar) {
      document.body.classList.add("mobileMenu");
    } else {
      document.body.classList.remove("mobileMenu");
    }
  }, [activeSidebar]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        document.body.style.overflow = "unset";
      } else {
        // Prevent scrolling when search is open
        document.body.style.overflow = isSearchOpen ? "hidden" : "unset";
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isSearchOpen]);

  useEffect(() => {
    setHasLoggedIn(() => (customerToken ? true : false));
  }, [customerToken]);


  const handleRedirect = () => {
    router.push('/'); // Replace with your destination URL
  };

  return (
    <div className="centerHeaderPart">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <div className="flex items-center justify-between">
              <div className="leftPart">
                <div className="relative">
                  <Button
                    theme="iconOnly"
                    icon="menu"
                    className="me-12 lg:hidden block"
                    onClick={openSidebar}
                  />
                </div>
                <Touchable onTouch={handleRedirect} className="flex items-center justify-center">
                <Link href="/" title="GSF" className="logo">
                  <ImageWithFallback
                    loading="eager"
                    width={74}
                    alt="Logo"
                    src={Logo}
                    height={74}
                  />
                </Link>
                </Touchable>              
                <SearchBox
                  t={t}
                  searching={searching}
                  isFocused={isFocused}
                  searchTerm={searchTerm}
                  isSearched={isSearched}
                  suggestions={suggestions}
                  setIsFocused={setIsFocused}
                  isMobileScreen={isMobileScreen}
                  enterKeyHandler={enterKeyHandler}
                  handleResultLink={handleResultLink}
                  handleClearSearch={handleClearSearch}
                  queryToSearchItems={queryToSearchItems}
                  handleSearchChange={handleSearchChange}
                />
              </div>
              <div className="right-part flex w-12/12 max-w-[320px] md:max-w-[455px] justify-end items-start">
                <div className="relative cartBox person-toolkit group hover:text-tertiary-white lg:me-[18px] me-12 cursor-pointer lg:flex hidden dropdownBox order-none">
                  <Button
                    prefetch={false}
                    theme="iconOnly"
                    type="link"
                    href={routes.branches}
                    icon="map"
                    className="map-toolkit"
                  >
                    <span className="lg:pe-12 flex text-body-text-3-sb lg:text-body-text-2-m text-primary-black group-hover:text-tertiary-white items-center ps-2">
                      {t("stores")}
                    </span>
                  </Button>
                  <CustomTooltip
                    anchorId=".map-toolkit"
                    content="Stores"
                    place="top"
                  />
                </div>
                <div
                  tabIndex={0}
                  role="button"
                  onMouseEnter={() => setIsDropdownOpen(true)}
                  onMouseLeave={() => setIsDropdownOpen(false)}
                  className={`cartBox person-toolkit group hover:text-tertiary-white lg:me-[18px] me-12 cursor-pointer flex relative dropdownBox lg:order-none order-3 ${
                    hasLoggedIn ? "accountArrow" : ""
                  }`}
                  onClick={(e) => {
                    if (hasLoggedIn) e.preventDefault();
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                >
                  <a
                    prefetch={false}
                    className="lg:pe-12 flex text-body-text-3-sb lg:text-body-text-2-m text-primary-black group-hover:text-tertiary-white items-center"
                    href={hasLoggedIn ? "#" : routes.signin}
                    onClick={(e) => {
                      if (hasLoggedIn) e.preventDefault();
                    }}
                  >
                    <Button
                      className="lg:flex hidden"
                      theme="iconOnly"
                      icon="person"
                    />
                    <Button
                      className="lg:hidden flex"
                      theme="iconOnly"
                      icon="personMobile"
                    />

                    <span className="ps-2 lg:flex hidden">
                      {hasLoggedIn ? t("dropdown.account") : t("signIn")}
                    </span>
                    {isDropdownOpen && (
                      <AccountTooltip
                        title={hasLoggedIn ? "My Account" : "Sign In"}
                      />
                    )}

                    {hasLoggedIn && (
                      <ArrowDesktop className="lg:flex hidden lg:ms-[1px] ms-0" />
                    )}
                  </a>
                  {hasLoggedIn && isDropdownOpen && (
                    <Dropdown
                      options={options}
                      onOptionClick={() => setIsDropdownOpen(false)}
                      router={router}
                      
                    />
                  )}
                </div>
                <div className="relative order-1">
                  <Button
                    theme="iconOnly"
                    icon="mbsearch"
                    className="search-tooltip me-12 sm:hidden flex"
                    onClick={handleSearchClick}
                  />
                  <CustomTooltip
                    anchorId=".search-tooltip"
                    content="Search"
                    place="top"
                  />
                </div>

                <div
                  tabIndex={0}
                  role="button"
                  className="cartBox group hover:text-tertiary-white cursor-pointer flex lg:order-none order-3"
                  onClick={openMiniTrolley}
                >
                  <div className="relative">
                    <Button
                      theme="iconOnly"
                      icon="cart"
                      className="cart lg:flex hidden"
                    />
                    <Button
                      theme="iconOnly"
                      icon="ShoppingMobile"
                      className="cart lg:hidden flex test"
                    />
                    <span className="number">{totalCount}</span>
                  </div>
                  <p className="ps-2 pe-12 lg:flex hidden text-body-text-3-sb lg:text-body-text-2-m text-primary-black group-hover:text-tertiary-white">
                    {getPrice(
                      cartPrice?.subtotal_including_tax?.value,
                      cartPrice?.subtotal_including_tax?.currency,
                    )}
                  </p>
                  <CustomTooltip
                    anchorId=".cart"
                    content="Basket"
                    place="top"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`w-12/12 ${isSearchOpen ? "bold-border" : "light-border"}`}
      ></div>

      <div
        id="mobile-search-box"
        className={`mbl-search-box w-12/12 ${
          isSearchOpen ? "fadeIn" : "fadeOut"
        }`}
      >
        <MblSearch
          t={t}
          searching={searching}
          isFocused={isFocused}
          searchTerm={searchTerm}
          isSearched={isSearched}
          suggestions={suggestions}
          setIsFocused={setIsFocused}
          isMobileScreen={isMobileScreen}
          enterKeyHandler={enterKeyHandler}
          handleResultLink={handleResultLink}
          handleClearSearch={handleClearSearch}
          queryToSearchItems={queryToSearchItems}
          handleSearchChange={handleSearchChange}
        />
      </div>
    </div>
  );
};
