"use client";

import Image from "next/image";
import { Autoplay } from "swiper/modules";
import { useTranslation } from "@/i18n/client";
import Truck from "@/assets/static/truck.svg?url";
import Phone from "@/assets/static/phone.svg?url";
import Kalarna from "@/assets/static/ico-klarna.svg?url";
import { Trans } from "react-i18next/TransWithoutContext";
import CommonSwiper from "@/components/common/shared/Slider";
import ClickPointer from "@/assets/static/clickPointer.svg?url";

export const TopBlueHeader = () => {
  const { t } = useTranslation(["header"]);

  const sliderConfig = {
    speed: 300,
    centeredSlides: true,
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
    },
    navigation: true,
    modules: [Autoplay],
  };

  const slides = [
    {
      content: (
        <div className="w-auto flex items-center sm:justify-start justify-center">
          <div className="imageBox">
            <Image
              width={24}
              height={24}
              alt="Truck"
              src={Truck}
              className="-mt-[2px]"
            />
          </div>
          <p className="text">
            <Trans t={t} i18nKey="usp.1">
              <span className="font-bold sm:me-[6px] ms-1 sm:ms-[10px] xs:me-2 me-1">
                Free Delivery
              </span>
              <span className="flex">For orders above £25</span>
            </Trans>
          </p>
        </div>
      ),
    },
    {
      content: (
        <div className="w-auto flex items-center sm:justify-start justify-center">
          <div className="imageBox xs:min-h-6 min-h-4 flex justify-center">
            <Image width={24} height="auto" alt="Phone" src={Kalarna} />
          </div>
          <p className="text">
          <Trans t={t} i18nKey="usp.2">
            <span className="font-bold sm:me-[6px] ms-1 sm:ms-[10px] xs:me-2 me-1">
              Klarna
            </span>
            <span className="flex"> - Pay in 30 Days</span>
          </Trans>
          </p>
        </div>
      ),
    },
    {
      content: (
        <div className="w-auto flex items-center sm:justify-start justify-center">
          <div className="imageBox">
            <Image
              width={24}
              height={24}
              alt="clickPointer"
              src={ClickPointer}
              className="-mt-[2px]"
            />
          </div>
          <p className="text">
            <Trans t={t} i18nKey="usp.3">
              <span className="font-bold sm:me-[6px] ms-1 sm:ms-[10px] xs:me-2 me-1">
                Free Click & Collect
              </span>
              <span className="flex">For all orders</span>
            </Trans>
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="topBlueHeader">
      <div className="container">
        <div className="row sm:flex hidden">
          <div className="col w-12/12">
            <div className="flex items-center justify-center xs:justify-between max-w-[1100px] mx-auto">
              <div className="w-auto flex items-center">
                <div className="imageBox">
                  <Image
                    width={24}
                    height={24}
                    alt="Truck"
                    src={Truck}
                    className="-mt-[2px]"
                  />
                </div>
                <p className="text">
                  <Trans t={t} i18nKey="usp.1">
                    <span className="font-bold sm:me-[6px] ms-1 xs:ms-[10px] xs:me-2 me-1">
                      Free Delivery
                    </span>
                    <span className="flex">For orders above £25</span>
                  </Trans>
                </p>
              </div>
              <div className="w-auto flex items-center">
                <div className="imageBox">
                  <Image
                    width={24}
                    height="auto"
                    alt="Phone"
                    src={Kalarna}
                    className="-mt-[2px]"
                  />
                </div>
                <p className="text">
                <Trans t={t} i18nKey="usp.2">
                  <span className="font-bold sm:me-[6px] ms-1 sm:ms-[10px] xs:me-2 me-1">
                    Klarna
                  </span>
                  <span className="flex"> - Pay in 30 Days</span>
                </Trans>
                </p>
              </div>
              <div className="w-auto flex items-center">
                <div className="imageBox">
                  <Image
                    width={24}
                    height={24}
                    alt="clickPointer"
                    src={ClickPointer}
                    className="-mt-[2px]"
                  />
                </div>
                <p className="text">
                  <Trans t={t} i18nKey="usp.3">
                    <span className="font-bold sm:me-[6px] ms-1 xs:ms-[10px] xs:me-2 me-1">
                      Free Click & Collect
                    </span>
                    <span className="flex">For all orders</span>
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row sm:hidden flex">
          <div className="col">
            <CommonSwiper
              slides={slides}
              config={sliderConfig}
              className="w-12/12"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
