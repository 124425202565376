"use client";

import Cookies from "js-cookie";
import useDebounce from "./useDebounce";
import { useAppDispatch } from "../store";
import { useRouter } from "next-nprogress-bar";
import { useTranslation } from "@/i18n/client";
import { no_products_found, search } from "../gtm";
import { metaInfo } from "../utils/helpers/meta_info";
import { useCallback, useEffect, useState } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { searchProductsAndCategories } from "../services/product";
import {
  endCommonLoading,
  startCommonLoading,
  updateLastSearchedTerm,
} from "../store/reducers/common";
import {
  routes,
  messages,
  isValidInput,
  getRedirectURL,
  handleToastError,
  getPageType,
} from "../utils";

export const useSearchItems = (props) => {
  const router = useRouter();
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const queryParams = useSearchParams();

  const { t } = useTranslation(["header", "common"]);

  const specialCoupon = Cookies.get("specialCoupon") || "";
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [suggestions, setSuggestions] = useState({});
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const updateSearchTerm = useCallback((textString) => {
    setSearchTerm(textString);
    const desktop = document.getElementById("desktop-search");
    const mobile = document.getElementById("mobile-search");
    if (desktop) desktop.value = textString;
    if (mobile) mobile.value = textString;
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 991) {
        setIsMobileScreen(false);
      } else {
        setIsMobileScreen(true);
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (pathname !== routes.searchResults) {
      handleClearSearch();
    }
  }, [pathname]);

  const handleTitleUpdate = (query) => {
    if (pathname === routes.searchResults) {
      try {
        const ele = document.querySelector("title");
        if (ele) {
          setTimeout(() => {
            ele.textContent = metaInfo.search_result.meta_title.replace(
              "{{ QUERY }}",
              query,
            );
          }, [2000]);
        }
      } catch (err) {
        console.warn(err);
      }
    }
  };

  const handleClearSearch = useCallback(() => {
    document.body.classList.remove("search-fixed");
    setSuggestions({});
    if (props?.afterSearch) props.afterSearch();
    setIsSearchOpen(false);
    updateSearchTerm("");
  }, [props, updateSearchTerm]);

  const removeSuggestionBox = useCallback(
    (redirectURL) => {
      router.push(redirectURL);
      router.refresh();
      setIsFocused(false);
      handleClearSearch();
    },
    [handleClearSearch, router],
  );

  const queryToSearchItems = useCallback(
    async (e, searchString = "", enterdSearch = false) => {
      const isSuggestionSearch = e && typeof e === "boolean";
      if (isSuggestionSearch) {
        setSearching(true);
      } else {
        dispatch(startCommonLoading());
      }

      if (!searchString) {
        handleToastError(t("messages.error.invalid_search_query"));
        if (isSuggestionSearch) {
          setSearching(false);
        } else {
          dispatch(endCommonLoading());
        }
        return;
      }

      const searchQ = searchString || searchTerm;
      const { data, error } = await searchProductsAndCategories({
        searchText: searchQ,
        specialCoupon,
      });

      if (!enterdSearch) {
        search({
          search_term: searchQ,
          number_of_results: data?.products?.total_count,
          search_type: data?.products.items.length
            ? "regular"
            : "no products found",
        });

        if (!data?.products?.items?.length) {
          no_products_found({
            page_type: getPageType(),
          });
        }
        dispatch(updateLastSearchedTerm(searchQ));
      }

      if (error) {
        handleToastError(error);
        if (isSuggestionSearch) {
          setSearching(false);
        } else {
          dispatch(endCommonLoading());
        }
        handleTitleUpdate(searchQ);
        return;
      }

      if (isSuggestionSearch) {
        const items = data?.products || {};
        setSuggestions({ ...items });
        setSearching(false);
        handleTitleUpdate(searchQ);
        return;
      }

      const { redirectURL } = getRedirectURL(data, searchTerm);
      dispatch(endCommonLoading());
      removeSuggestionBox(redirectURL);
      setIsSearchOpen(false);
      if (props?.afterSearch) props.afterSearch();
    },
    [dispatch, props, removeSuggestionBox, searchTerm],
  );

  const handleResultLink = useCallback(
    (e) => {
      const anchor = e.target.closest("a.search-result-link");
      if (anchor) {
        e.preventDefault();
        removeSuggestionBox(anchor.getAttribute("href"));
      }
    },
    [removeSuggestionBox],
  );

  const enterKeyHandler = useCallback(
    (e) => {
      if (e.key === "Enter") {
        const searchString = e.target.value.trim();
        e.preventDefault();

        // Immediately set the search term
        setSearchTerm(searchString);

        // Trigger the search immediately with the entered searchString
        queryToSearchItems(e, searchString, true);
      }
    },
    [queryToSearchItems],
  );

  const handleSearchChange = useCallback((e) => {
    const searchString = e.target.value.trim();

    // Set the search term immediately to update the state
    setSearchTerm(searchString);

    // If the search string is valid and longer than 2 characters, debounce the search
    if (searchString.length > 2) {
      debouncedQuerySearch(searchString);
    } else {
      setSuggestions({});
    }
  }, []);

  const debouncedQuerySearch = useDebounce((searchString) => {
    if (isValidInput(searchString)) {
      queryToSearchItems(true, searchString);
    } else {
      handleToastError(messages?.error?.invalidInput);
    }
  }, 500);

  useEffect(() => {
    if (routes?.searchResults === pathname) {
      const search = queryParams.get("q");
      updateSearchTerm(search);
    }
  }, [queryParams, updateSearchTerm, pathname]);

  return {
    data: {
      searching,
      isFocused,
      searchTerm,
      suggestions,
      isSearchOpen,
      isMobileScreen,
      isSearched: Object.keys(suggestions).length > 0,
    },
    methods: {
      t,
      router,
      dispatch,
      setIsFocused,
      setSearchTerm,
      setIsSearchOpen,
      enterKeyHandler,
      handleResultLink,
      handleSearchChange,
      handleClearSearch,
      queryToSearchItems,
    },
  };
};
