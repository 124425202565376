"use client";

import { useCallback } from "react";
import debounce from "lodash/debounce";

export const useDebounce = (fnToDebounce, durationInMs = 500) => {
  if (isNaN(durationInMs)) {
    throw new TypeError("dely for debounce should be a number");
  }

  if (fnToDebounce == null) {
    throw new TypeError("function to debounce cannot be null");
  }

  if (typeof fnToDebounce !== "function") {
    throw new TypeError("function to debounce should be a function");
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(fnToDebounce, durationInMs), [
    fnToDebounce,
    durationInMs,
  ]);
};

export default useDebounce;
